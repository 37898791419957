<template>
  <div class="row">
    <div class="col-lg-4 p-r">
      <h6 class="pb-5 inputLabel secondMobileFontSize labelColor" style="padding-right:16%; padding-top:10px">
        {{name}}</h6>
    </div>
    <div class="col-lg-8 mp">
      <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
        <v-select
          :class="{ 'customWidth': customWidth}" :multiple="isMultiple" :options="options" :placeholder="placeholder"
          :style="[errors.length > 0 ? {'border-color': '#DE3557'} : {}]" @input="selectAction"
          class="style-chooser form-control"
          dir="rtl"
          v-model="computedValue"></v-select>
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';

  export default {
    name: "formsInput",
    props: {
      name: {type: String, required: false},
      value: {type: [Number, String, Array]},
      placeholder: {type: String, default: ''},
      validationRules: {type: String, required: true},
      selectAction: {type: Function, default: null},
      options: {type: Array, required: true},
      isMultiple: {type: Boolean, default: false},
      customWidth: {type: Boolean, default: false},
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },

  }
</script>

<style>
  @import '../../../../public/assets/css/formsStyle.css';
</style>
